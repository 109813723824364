import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useStretchesOfCourseQuery } from '@innovamat/glow-api-client';
import { usePrint } from '@innovamat/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';
import styled from 'styled-components';
import { PrintFooter } from '../print-guides';
import {
  ActivityImage,
  ActivityInfo,
  Bold,
  FileDescription,
  FileTitle,
  GlobalStyle,
  HeaderBar,
  Stretch,
  StyledTable,
  TabletTitle,
  TBodyCell,
  THeadCell,
  Wrapper,
} from './print-us-styled';

type Lesson = {
  title: string;
  type: string;
  duration: number;
  image: string;
};

type ModulePacings = {
  title: string;
  lessons?: Lesson[] | null;
};

type AssessmentPacing = {
  title: string;
  lessons?: Lesson[] | null;
};

type StretchOfCourse = {
  title: string;
  number: number;
  modulePacings: ModulePacings[];
  assessmentPacing?: AssessmentPacing | null;
};

const description = `
<p>This stretch focuses on developing foundational number sense through <b>rote counting and cardinal counting</b> activities. Students work with manipulatives like the <b>20-bead string</b> and explore addition through <b>put-together situations</b>.</p>
<p>The sequence progresses to <b>skip counting</b> and <b>backward counting</b> while incorporating <b>base 10 grouping</b> concepts. Each module builds upon previous skills, culminating in a review assessment.</p>
`;

const ContentTable = styled.table`
  width: 100%;
  margin-top: 24px;
  border-collapse: separate;
  border: none;
  border-spacing: 7px;
`;

const THeadRow = styled.tr`
  font-weight: 500;
  text-align: center;

  th {
    padding: 5px;
    border-radius: 4px;
    align-items: center;
    background-color: #f1f3f2;
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
  }
`;

const TableCell = styled.td`
  border-radius: 4px;
`;

const LastDayTableCell = styled(TableCell)`
  background-color: #eaf7fb;
  text-align: center;

  div {
    padding: 0px 10px;
  }
`;

const AssessmentImage = styled.img`
  object-position: bottom;
  width: 100%;
  height: 72px;
  background-color: #e0f3f0;
  border-radius: 4px;
  object-fit: contain;
  flex-shrink: 0;
`;

const RowHeader = styled(TableCell)`
  min-height: 82px;
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
`;

const AssessmentRowHeader = styled(RowHeader)`
  height: 82px;
  line-height: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const LessonTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  height: 48px;
  width: 128px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const DAYS_OF_WEEK = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'];

const PrintStretch: React.FC<StretchOfCourse> = ({
  title,
  modulePacings,
  assessmentPacing,
}) => {
  const { t } = useTranslation();
  const hasSharedTheFifthDayColumn = modulePacings.every(
    (module) => module?.lessons?.[4]?.type === 'Digital Practice'
  );
  const individualizedPracticeLesson =
    hasSharedTheFifthDayColumn && modulePacings[0]?.lessons?.[4];

  return (
    <StyledTable>
      <thead>
        <tr>
          <THeadCell colSpan={1}>
            <HeaderBar className="header-bar">
              <FileTitle>
                {t('pacingGuide.title', { defaultValue: 'Pacing Guide' })}
              </FileTitle>
              <TabletTitle>
                <Stretch>{title}</Stretch>
              </TabletTitle>
            </HeaderBar>
          </THeadCell>
        </tr>
      </thead>
      <tbody>
        <tr>
          <TBodyCell>
            <FileDescription>
              <Trans
                i18nKey={t('personalizedPractice.description')}
                defaults={description}
                components={{
                  b: <Bold />,
                  p: <p />,
                  br: <br />,
                }}
              />
            </FileDescription>
            <ContentTable>
              <thead>
                <THeadRow>
                  <tr />
                  {DAYS_OF_WEEK.map((day, index) => (
                    <THeadCell key={index}>{day}</THeadCell>
                  ))}
                </THeadRow>
              </thead>
              <tbody>
                {modulePacings.map((module, rowIndex, modulesArray) => {
                  return hasSharedTheFifthDayColumn ? (
                    <tr key={module.title}>
                      <RowHeader>{module.title}</RowHeader>
                      {(module.lessons ?? [])
                        .slice(0, 4)
                        .map(({ title, duration, image }) => (
                          <TableCell key={title} colSpan={duration}>
                            <ActivityImage src={image} alt={title} />
                            <LessonTitle>{title}</LessonTitle>
                          </TableCell>
                        ))}

                      {rowIndex === 0 && (
                        <LastDayTableCell rowSpan={modulesArray.length}>
                          {individualizedPracticeLesson && (
                            <>
                              <ActivityImage
                                src={individualizedPracticeLesson.image}
                                alt={individualizedPracticeLesson.title}
                              />
                              <ActivityInfo>
                                {individualizedPracticeLesson.title}
                              </ActivityInfo>
                            </>
                          )}
                        </LastDayTableCell>
                      )}
                    </tr>
                  ) : (
                    <tr key={module.title}>
                      <RowHeader>{module.title}</RowHeader>
                      {(module.lessons ?? []).map(
                        ({ title, duration, image }) => (
                          <TableCell key={title} colSpan={duration}>
                            <ActivityImage src={image} alt={title} />
                            <LessonTitle>{title}</LessonTitle>
                          </TableCell>
                        )
                      )}
                    </tr>
                  );
                })}

                {assessmentPacing && (
                  <tr>
                    <AssessmentRowHeader>
                      {assessmentPacing.title}
                    </AssessmentRowHeader>
                    {(assessmentPacing.lessons ?? []).map(
                      ({ title, duration, image }) => (
                        <TableCell key={title} colSpan={duration}>
                          <AssessmentImage src={image} alt={title} />
                          <LessonTitle>{title}</LessonTitle>
                        </TableCell>
                      )
                    )}
                  </tr>
                )}
              </tbody>
            </ContentTable>
          </TBodyCell>
        </tr>
      </tbody>
    </StyledTable>
  );
};

export function PrintPacingGuide(): EmotionJSX.Element {
  const { courseId } = useParams();
  const { data } = useStretchesOfCourseQuery({
    courseUuid: courseId || '',
  });
  const { componentRef, loading, onPrint } = usePrint(
    `Pacing Guide-${courseId}`
  );

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <PrintFooter onPrint={onPrint} loading={loading} />
        <div ref={componentRef}>
          {data?.stretchesOfCourse.map((stretch, index) => (
            <PrintStretch key={index} {...stretch} />
          ))}
        </div>
      </Wrapper>
    </>
  );
}
