import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import type { CustomColumnRenderConfig } from '@innovamat/glimmer-components';
import {
  TableComponent,
  useColumnsCustomRenderConfig,
} from '@innovamat/glimmer-components';

import { TestStatusCell } from '../../../test-and-results-table/components/test-status-cell';
import {
  StatementsCol,
  StatementsContainer,
  useMockExamsDrawerTableColumnsConf,
} from '../../hooks/use-drawer-table-columns-conf';
import { ScoreCell } from '../score-cell';
import { StatementCell } from '../statement-cell/statement-cell';

type Statement = {
  id: string;
  answer: string;
  isCorrect: boolean;
};

type StatementMetadata = {
  position?: number;
  answersMetrics?: {
    largestAnswerWidth?: number;
  };
};

type MockExamsStudentRow = {
  id: string;
  firstName: string;
  lastName: string;
  score: number;
  status: string;
  statements: Statement[];
};

type MockExamsDrawerTableProps = {
  students: MockExamsStudentRow[];
  numStatements: number;
  statementsMetadata?: StatementMetadata[];
};

const customColumnsRenderConfig = (
  t: TFunction,
  statementsMetadata?: StatementMetadata[]
): CustomColumnRenderConfig<MockExamsStudentRow> => ({
  firstName: (value: string) => {
    return value || t('common.unknown');
  },
  lastName: (value: string) => {
    return value;
  },
  status: (value: string) => {
    return <TestStatusCell status={value} />;
  },
  score: (value: number) => {
    return <ScoreCell score={value} />;
  },
  statements: (value: Statement[]) => {
    return (
      <StatementsContainer>
        {value.map((statement, index) => {
          const width =
            statementsMetadata?.find((s) => s.position === index)
              ?.answersMetrics?.largestAnswerWidth ?? 0;

          return (
            <StatementsCol width={width} key={statement.id}>
              <StatementCell
                answer={statement.answer}
                isCorrect={statement.isCorrect}
              />
            </StatementsCol>
          );
        })}
      </StatementsContainer>
    );
  },
});

function MockExamsDrawerTable({
  students,
  numStatements,
  statementsMetadata,
}: MockExamsDrawerTableProps): JSX.Element {
  const { t } = useTranslation();
  const columnsWithoutRender = useMockExamsDrawerTableColumnsConf(
    numStatements,
    statementsMetadata ?? []
  );
  const columns = useColumnsCustomRenderConfig<MockExamsStudentRow>(
    customColumnsRenderConfig(t, statementsMetadata ?? []),
    columnsWithoutRender
  );

  return (
    <TableComponent
      columns={columns}
      rows={students}
      id="mock-exams-drawer-table"
    />
  );
}

export { MockExamsDrawerTable };
export type { MockExamsStudentRow, StatementMetadata };
