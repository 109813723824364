'use client';

import { Route, Routes } from 'react-router-domv6';
import 'react-toastify/dist/ReactToastify.css';
import '../config/i18n';

import {
  APP_PATHS,
  AppletSelector,
  ForgotPassword,
  LeadRegister,
  LinkSchool,
  Login,
  ManipulativeEnvironment,
  PrintGuides,
  PrintPacingGuide,
  PrintPersonalizedPractice,
  RegisterUser,
  ReportsFullScreenProvider,
  ResetPassword,
  useAppHeight,
  useUser,
} from '@innovamat/ga-features';
import { SnackBarContainer } from '@innovamat/glimmer-components';
import {
  isUSRegion,
  RegionalConfigVariablesProvider,
} from '@innovamat/regional-config-variables';

import { AdminClassroomNavigationWrapper } from './components/admin-classroom-navigation-wrapper';
import { AdminNavigationWrapper } from './components/admin-navigation-wrapper';
import { ClassroomNavigationWrapper } from './components/classroom-navigation-wrapper';
import { CourseNavigationWrapper } from './components/course-navigation-wrapper';
import { DigitalPracticeNavigationWrapper } from './components/digital-practice-navigation-wrapper';
import { InitDatadog } from './components/init-datadog';
import { LocaleManager } from './components/locale-manager';
import { Providers } from './components/providers';
import { ResourceRouter } from './components/resource-router';
import { StudentViewManagement } from './components/student-view-management';

import { storage } from '@innovamat/radiance-utils';
import { BrowserRouter } from 'react-router-domv6';
import { CORPORATE_WEBSITE, ENVIRONMENT } from '../utils/variables';
import { EmailPreferencesPage } from './components/email-preferences-page';
import { Maintenance } from './components/maintenance';
import { NotFound } from './components/not-found';
import { ProtectedRoute } from './components/protected-route';
import { CourseSelectorPage } from './pages/course-selector-page';
import { FamilyPaymentsTeachersPage } from './pages/family-payments-teachers-page';
import { JoinToClassroomPage } from './pages/join-to-classroom-page';
import { LandingPage } from './pages/landing-page';
import { SettingsPage } from './pages/settings-page';

storage.initRedirectIfTokenIsInvalid({ redirectUrl: '/' });

function HomeRoute() {
  const { isLoggedIn } = useUser();

  if (!isLoggedIn) return <Login />;

  return (
    <ProtectedRoute>
      <LandingPage />
    </ProtectedRoute>
  );
}

export async function ManagerRouting(): Promise<JSX.Element> {
  useAppHeight();

  const environment = ENVIRONMENT || 'local';
  const fastApiUrl = CORPORATE_WEBSITE || '';
  const region = (await isUSRegion(fastApiUrl)) ? 'us' : 'nonUs';

  return (
    <BrowserRouter>
      <RegionalConfigVariablesProvider
        environment={environment}
        region={region}
      >
        <Providers>
          <SnackBarContainer autoClose={3000} closeButton={false} />
          <LocaleManager />
          <StudentViewManagement />
          <InitDatadog />

          <Routes>
            <Route path={APP_PATHS.MAINTENANCE} element={<Maintenance />} />

            <Route
              path={`/:lng${APP_PATHS.FORGOT_PASSWORD_URL}`}
              element={<ForgotPassword />}
            />
            <Route
              path={`/:lng${APP_PATHS.RESET_PASSWORD_URL}`}
              element={<ResetPassword />}
            />
            <Route
              path={`/:lng${APP_PATHS.LEAD_REGISTER_URL}`}
              element={<LeadRegister />}
            />
            <Route
              path={`/:lng${APP_PATHS.COMPLETE_USER_INVITATION_URL}`}
              element={<RegisterUser />}
            />
            <Route
              path={`/:lng${APP_PATHS.COMPLETE_PARENT_INVITATION_URL}`}
              element={<RegisterUser />}
            />
            <Route
              path={`/:lng${APP_PATHS.LINK_SCHOOL_URL}`}
              element={<LinkSchool />}
            />

            <Route path={APP_PATHS.LANDING_PAGE_URL} element={<HomeRoute />} />

            <Route
              path={`${APP_PATHS.PREFERENCES_URL}/:encodedMail`}
              element={<EmailPreferencesPage />}
            />

            <Route
              path={APP_PATHS.SETTINGS_URL}
              element={
                <ProtectedRoute>
                  <SettingsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.MANIPULATIVE_URL}
              element={
                <ProtectedRoute>
                  <ManipulativeEnvironment />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.APPLETS_URL}
              element={
                <ProtectedRoute>
                  <AppletSelector />
                </ProtectedRoute>
              }
            />

            <Route
              path={APP_PATHS.JOIN_TO_CLASSROOM_URL}
              element={
                <ProtectedRoute>
                  <JoinToClassroomPage />
                </ProtectedRoute>
              }
            />

            {/* COURSE SELECTOR */}
            <Route
              path={`${APP_PATHS.COURSE_SELECTOR_BASE_URL}/:navType`}
              element={
                <ProtectedRoute>
                  <ReportsFullScreenProvider>
                    <CourseSelectorPage />
                  </ReportsFullScreenProvider>
                </ProtectedRoute>
              }
            />

            {/*TEACHER FAMILY PAYMENTS */}
            <Route
              path={APP_PATHS.FAMILY_PAYMENTS_URL}
              element={
                <ProtectedRoute>
                  <FamilyPaymentsTeachersPage />
                </ProtectedRoute>
              }
            />

            {/* CLASSROOM NAVIGATION */}
            <Route path={`${APP_PATHS.CLASSROOM_URL}/:classroomId`}>
              <Route
                path=":menu?/:submenu?/page"
                element={
                  <ProtectedRoute>
                    <ReportsFullScreenProvider>
                      <ClassroomNavigationWrapper />
                    </ReportsFullScreenProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path=":menu/print/:ids"
                element={
                  <ProtectedRoute>
                    <PrintGuides />
                  </ProtectedRoute>
                }
              />
              <Route path=":menu/:type/:id" element={<ResourceRouter />} />
            </Route>

            {/* PUBLIC RESOURCES */}
            <Route path="/:menu/:type/:id" element={<ResourceRouter />} />

            {/* PRINT GUIDES */}
            <Route
              path="/:menu/print/:ids"
              element={
                <ProtectedRoute>
                  <PrintGuides />
                </ProtectedRoute>
              }
            />

            {/* PRINT US MODULES */}
            <Route
              path="/print-us-modules/:courseId"
              element={
                <ProtectedRoute>
                  <PrintPersonalizedPractice />
                </ProtectedRoute>
              }
            />

            {/* PRINT US PACING GUIDE */}
            <Route
              path="/print-pacing-guide/:courseId"
              element={
                <ProtectedRoute>
                  <PrintPacingGuide />
                </ProtectedRoute>
              }
            />

            {/* COURSE NAVIGATION */}
            <Route path={`${APP_PATHS.COURSE_URL}/:courseId`}>
              <Route
                path="applets"
                element={
                  <ProtectedRoute>
                    <AppletSelector />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":menu?/:submenu?/page"
                element={
                  <ProtectedRoute>
                    <CourseNavigationWrapper />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":menu/print/:ids"
                element={
                  <ProtectedRoute>
                    <PrintGuides />
                  </ProtectedRoute>
                }
              />
              <Route path=":menu/:type/:id" element={<ResourceRouter />} />
            </Route>

            {/* DIGITAL PRACTICE */}
            <Route path={`${APP_PATHS.DIGITAL_PRACTICE_URL}/:classroomId`}>
              <Route
                path=":menu?/:submenu?/page"
                element={
                  <ProtectedRoute>
                    <DigitalPracticeNavigationWrapper />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":menu/:type/:id"
                element={
                  <ProtectedRoute>
                    <ResourceRouter />
                  </ProtectedRoute>
                }
              />
            </Route>

            {/* ADMIN NAVIGATION */}
            <Route path={`${APP_PATHS.ORGANIZATION_URL}/:organizationId`}>
              <Route
                path="classrooms/:classroomId/:menu?/:submenu?/page"
                element={
                  <ProtectedRoute>
                    <ReportsFullScreenProvider>
                      <AdminClassroomNavigationWrapper />
                    </ReportsFullScreenProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path=":menu?/:submenu?"
                element={
                  <ProtectedRoute>
                    <AdminNavigationWrapper />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Providers>
      </RegionalConfigVariablesProvider>
    </BrowserRouter>
  );
}

export default ManagerRouting;
