import { useEffect, useState } from 'react';

type Props = {
  timeInSeconds: number;
};

type TimeParts = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const useTimeFormatter = ({ timeInSeconds }: Props): TimeParts => {
  const getTimeParts = (seconds: number): TimeParts => {
    const totalSeconds = Math.floor(seconds);
    const days = Math.floor(totalSeconds / 86400);
    const remainingAfterDays = totalSeconds % 86400;
    const hours = Math.floor(remainingAfterDays / 3600);
    const remainingAfterHours = remainingAfterDays % 3600;
    const minutes = Math.floor(remainingAfterHours / 60);
    const remainingSeconds = remainingAfterHours % 60;

    return { days, hours, minutes, seconds: remainingSeconds };
  };

  const [timeParts, setTimeParts] = useState<TimeParts>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const parts = getTimeParts(timeInSeconds);
    setTimeParts(parts);
  }, [timeInSeconds]);

  return timeParts;
};

export { useTimeFormatter };
export type { TimeParts };
