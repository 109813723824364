import { ReactComponent as AppStoreBadge } from '../assets/images/app-store-badge.svg';
import { ReactComponent as BlocksPreferences } from '../assets/images/blocks-preferences.svg';
import { ReactComponent as ElectrifiedCat } from '../assets/images/electrified-cat.svg';
import { ReactComponent as EmptyClassroomShadow } from '../assets/images/empty-classroom-shadow.svg';
import { ReactComponent as EmptyClassroom } from '../assets/images/empty-classroom.svg';
import { ReactComponent as EmptyFamilyPayments } from '../assets/images/empty-family-payments.svg';
import { ReactComponent as EmptyReport } from '../assets/images/empty-report.svg';
import { ReactComponent as EmptyTestsAndResults } from '../assets/images/empty-tests-and-results.svg';
import { ReactComponent as GooglePlayBadge } from '../assets/images/google-play-badge.svg';
import { ReactComponent as GoogleImg } from '../assets/images/google.svg';
import { ReactComponent as HappyStudentsWithTeacher } from '../assets/images/happy-students-with-teacher.svg';
import { ReactComponent as IconsFaces } from '../assets/images/icons-faces.svg';
import { ReactComponent as LogoInnovamat } from '../assets/images/login/logo.svg';
import { ReactComponent as LogoInnovamatMobile } from '../assets/images/login/mobile-logo.svg';
import { ReactComponent as WavesBottomBg } from '../assets/images/login/ones_inferior.svg';
import { ReactComponent as WavesTopBg } from '../assets/images/login/ones_superior.svg';
import { ReactComponent as WavesBg } from '../assets/images/login/waves.svg';
import { ReactComponent as MicrosoftImg } from '../assets/images/microsoft.svg';
import { ReactComponent as Superman } from '../assets/images/superman.svg';

import RegisterBg from '../assets/images/login/registration-form-bg.png';
import UEFlag from '../assets/images/login/ue-flag.png';
import MaintenanceImg from '../assets/images/maintenance.png';
import ClassesEmptyStateImage from '../assets/images/reports-error-image.png';

export * from './lib';

export {
  AppStoreBadge,
  BlocksPreferences,
  ClassesEmptyStateImage,
  ElectrifiedCat,
  EmptyClassroom,
  EmptyClassroomShadow,
  EmptyFamilyPayments,
  EmptyReport,
  EmptyTestsAndResults,
  GoogleImg,
  GooglePlayBadge,
  HappyStudentsWithTeacher,
  IconsFaces,
  LogoInnovamat,
  LogoInnovamatMobile,
  MaintenanceImg,
  MicrosoftImg,
  RegisterBg,
  Superman,
  UEFlag,
  WavesBg,
  WavesBottomBg,
  WavesTopBg,
};
