import { Option, Session } from '@innovamat/resource-viewer';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useEventLogging } from '@innovamat/event-logging';
import { useChallengesQuery } from '@innovamat/glow-api-client';
import { useQueryParams } from '@innovamat/hooks';
import { ThemeProvider } from '@innovamat/innova-components';
import { Region } from '@innovamat/localization';
import {
  DigitalGuideProvider,
  DigitalGuideWrapper,
  getImagesFromPreprResource,
  ScrollPosition,
  Separator,
  SmartGuide,
  SmartGuideSkeleton,
  usePreprTracking,
} from '@innovamat/smart-guides';
import { useEffect } from 'react';
import { useParams } from 'react-router-domv6';
import { authAxiosInstance } from '../../../../utils';
import { useEnvVariables } from '../../../env-variables-manager';
import { useCurrentNavigationValues } from '../../../navigation/hooks/use-current-navigation-values';
import { useUser } from '../../../user-management';
import { RelatedResourcesList, UnblockedAppletsList } from '../../components';
import { Layout } from '../../components/resource-layout/layout';
import { useAppletInfo, useRegionParameters } from '../../hooks';
import useGetPreprResource from '../../hooks/use-get-prepr-resource';
import { useHandleChallenge } from '../../hooks/use-handle-challenge';
import { onResourceViewerEvents } from '../../utils/resource-viewer-events';
import { SpacesWrapper } from './spaces-wrapper';

type Props = {
  language: string;
  region: string;
  scrollPosition: ScrollPosition;
  session: Session;
  setScrollPosition: any;
  translations: {};
  onDownloadOption: (option?: Option | undefined) => void;
  onGoBack: () => void;
};

export function PreprResource({
  language,
  region,
  scrollPosition,
  session,
  setScrollPosition,
  translations,
  onDownloadOption,
  onGoBack,
}: Props): JSX.Element | null {
  const { t } = useTranslation();
  const { classroomId } = useParams();
  const { isRole, user, isLoggedIn } = useUser();
  const { courseOrder } = useCurrentNavigationValues();
  const { setEventData } = useEventLogging();
  const { APOLLO_SERVER } = useEnvVariables();
  const params = useQueryParams();

  const organization = user?.organization;
  const organizationId = organization?.id;
  const organizationName = organization?.name;
  const name = user?.name;
  const email = user?.email;
  const userId = user?.id;
  const _courseOrder = courseOrder || user?.studentData?.courseOrder;
  const urlPart = params.get('part') || undefined;

  const { regionParameters } = useRegionParameters();

  const isStudent = isRole.Student;
  const isTeacher = isRole.Teacher;
  const academicYearId = organization?.academicYearId;
  const id = session.guide?.id!;

  const { data: preprResource, isLoading } = useGetPreprResource({
    id,
    locale: language,
    region: region as Region,
    enabled: !!id && !!language,
    session: session,
  });

  const { setSendViewEventId } = usePreprTracking();

  useEffect(() => {
    setSendViewEventId(id);
  }, [id]);

  const challengesBody = {
    body: {
      academicYearId: academicYearId!,
      classroomId: classroomId || 'self',
      sessionId: session.id,
    },
  };
  const { data } = useChallengesQuery(challengesBody, {
    enabled: isLoggedIn,
  });

  const challenges = data?.challenges || [];

  const appletInfo = useAppletInfo();

  const isSpaceWithCallenges =
    preprResource?.type === 'digitalGuide' && 'challenges' in preprResource;

  const { handleMarkChallenge } = useHandleChallenge({
    classroomId: classroomId!,
    sessionId: session.id,
    academicYearId: academicYearId!,
    enabled: isSpaceWithCallenges,
    preprResource,
    session,
  });

  if (isLoading) return <SmartGuideSkeleton />;

  if (!preprResource) return <code>No data</code>;

  if (preprResource.type === 'space') {
    return <SpacesWrapper spaceSession={preprResource} onGoBack={onGoBack} />;
  }

  const getRelatedItems = () => {
    if (session.linkedLists && session.linkedLists.length > 0) {
      return (
        <div>
          <Separator />
          <RelatedResourcesList
            language={language}
            region={region}
            linkedLists={session.linkedLists}
            sessionId={session.id}
            sessionName={session.title}
          />
        </div>
      );
    }
    return undefined;
  };

  const getUnblockedAppletsItems = () => {
    if (preprResource.type === 'smartGuide') {
      if (preprResource.__typename === 'SmartGuideTaller') return undefined;
    }

    if (session.code) {
      return (
        <UnblockedAppletsList
          appletsCode={session.code}
          resourceId={session.id}
        />
      );
    }
    return undefined;
  };

  if (preprResource.type === 'digitalGuide') {
    const images = getImagesFromPreprResource(preprResource);
    const canMarkChallengeAsDone = isTeacher && !!classroomId;
    return (
      <Layout
        title={session.title}
        description={t(preprResource!.subtitle.body)}
        id={session.id}
        printables={session.printables}
        handleDownloadOption={onDownloadOption}
        setScrollPosition={setScrollPosition}
        onGoBack={onGoBack}
        isStudent={isStudent}
      >
        <ThemeProvider>
          <DigitalGuideProvider
            id={session.id}
            playerInfo={{
              isStudent,
              setEventData,
              onResourceViewerEvents: (prevState, state, action) =>
                onResourceViewerEvents({
                  prevState,
                  state,
                  action,
                  setEventData,
                }),
              resource: session,
              appletInfo: appletInfo,
              school: organization?.id!,
              uid: userId!,
              axiosInstance: authAxiosInstance,
              apolloServer: APOLLO_SERVER,
            }}
            translations={translations}
            relatedItemsComponent={getRelatedItems()}
            unblockedAppletsComponent={getUnblockedAppletsItems()}
            user={{
              language,
              classroomId,
              organizationId,
              organizationName,
              userId,
              region,
              courseOrder: _courseOrder!,
              email,
              name,
            }}
            t={t}
          >
            <DigitalGuideWrapper
              guide={preprResource}
              images={images}
              challenges={challenges}
              resource={{ ...session, language: language || 'es' }}
              canMarkAsDone={canMarkChallengeAsDone}
              onMarkChallengeAsDone={handleMarkChallenge}
              scrollPosition={scrollPosition}
              onEvent={setEventData}
              regionParameters={regionParameters}
              isStudent={isStudent}
              t={t}
            />
          </DigitalGuideProvider>
        </ThemeProvider>
      </Layout>
    );
  }

  if (preprResource.type === 'smartGuide') {
    return (
      <Layout
        title={session.title}
        description={t(preprResource.subtitle)}
        id={session.id}
        handleDownloadOption={onDownloadOption}
        setScrollPosition={setScrollPosition}
        printables={isStudent ? session.printables : undefined}
        onGoBack={onGoBack}
        isStudent={isStudent}
      >
        <SmartGuide
          playerInfo={{
            isStudent,
            setEventData,
            onResourceViewerEvents: (prevState, state, action) =>
              onResourceViewerEvents({
                prevState,
                state,
                action,
                setEventData,
              }),
            resource: session,
            appletInfo: appletInfo,
            school: organization?.id!,
            uid: userId!,
            axiosInstance: authAxiosInstance,
            apolloServer: APOLLO_SERVER,
          }}
          smartGuide={preprResource}
          translations={translations}
          t={t}
          session={session}
          relatedItemsComponent={getRelatedItems()}
          unblockedAppletsComponent={getUnblockedAppletsItems()}
          setEventData={setEventData}
          region={region}
          urlPart={urlPart}
        />
      </Layout>
    );
  }

  return null;
}
