import {
  Modal,
  Select,
  Typography,
  snack,
} from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useClassrooms } from '../../../hooks/use-classrooms';
import { Student } from '@innovamat/glow-api-client';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useRecoverStudent } from '../../../hooks/use-recover-student';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  student: Student | undefined;
  isOpen: boolean;
  onClose: () => void;
};

const Content = styled.div`
  margin-top: 32px;
`;

function RecoverStudentModal({ isOpen, onClose, student }: Props): JSX.Element {
  const { t } = useTranslation();
  const { classroomsByStage, classroomExists, classroomToOption } =
    useClassrooms({ mine: false });
  const [classroomId, setClassroomId] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();

  const { isPending, onRecoverStudent, isError } = useRecoverStudent({
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['Organization'] });
      queryClient.refetchQueries({ queryKey: ['SchoolStudents'] });
      onClose();
    },
    onError: (errors) => {
      errors.response.errors.forEach((error) => {
        if (error.extensions?.response?.['status'] === 409) {
          snack.error(t('students.alert.maxStudentsLength'));
        }
      });
    },
  });

  const filterGoogleClassrooms = true;

  useEffect(() => {
    const classExists = classroomExists(student?.classroomId);
    setClassroomId(classExists ? student?.classroomId! : undefined);
  }, [classroomExists, student?.classroomId]);

  const handleSubmit = (): void => {
    if (!student) return;
    const classroomChanged = student?.classroomId !== classroomId;
    if (classroomChanged) onRecoverStudent({ student, classroomId });
    else onRecoverStudent({ student });
  };

  const classroomsOptions = classroomsByStage(
    student?.courseOrder!,
    filterGoogleClassrooms
  ).map(classroomToOption);

  return (
    <Modal
      title={t('students.table.recover')}
      onClose={onClose}
      isOpen={isOpen}
      buttons={[
        {
          children: t('common.recover'),
          'aria-label': t('common.recover'),
          onClick: handleSubmit,
          variant: 'accent',
          role: 'button',
          loading: isPending && !isError,
          disabled: !classroomId,
        },
        {
          children: t('common.notNow'),
          'aria-label': t('common.notNow'),
          variant: 'tertiary',
          onClick: onClose,
          role: 'button',
        },
      ]}
      onSubmit={isPending ? undefined : handleSubmit}
    >
      <>
        <Typography.Body1>{t('students.recover.subtitle')}</Typography.Body1>
        <Content>
          <Select
            menuPortalTarget={document.body}
            labelText={t('select.student.classroom')}
            options={classroomsOptions}
            onChange={(value) => setClassroomId(value?.value)}
            value={classroomId}
          />
        </Content>
      </>
    </Modal>
  );
}

export { RecoverStudentModal };
