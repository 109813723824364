import { IconButton } from '../icon-button';
import { Popover } from '../popover';

type ClassCardOptions = {
  delete?: { text: string; onClick: () => void; subtitle?: string };
  edit?: { text: string; onClick: () => void };
  leave?: { text: string; onClick: () => void };
  unsynchronize?: { text: string; onClick: () => void; subtitle?: string };
};

type ClassCardOptionsProps = {
  isUpdatePeriod?: boolean;
  options?: ClassCardOptions;
  students: number;
  onOpenOptions?: () => void;
};

function ClassCardOptions({
  isUpdatePeriod = false,
  options = {
    delete: { text: '', onClick: () => {} },
    edit: { text: '', onClick: () => {} },
    leave: { text: '', onClick: () => {} },
    unsynchronize: { text: '', onClick: () => {} },
  },
  students,
  onOpenOptions,
}: ClassCardOptionsProps): JSX.Element {
  return (
    <Popover
      popoverProps={{ disablePortal: true }}
      id="class-card"
      trigger={
        <IconButton
          icon="OptionsIcon"
          size="S"
          onClick={() => onOpenOptions?.()}
        />
      }
    >
      <div style={{ minWidth: 264 }}>
        {options.edit && (
          <Popover.Item
            state={isUpdatePeriod ? 'disabled' : 'active'}
            icon="EditIcon"
            onSelectItem={options.edit.onClick}
          >
            {options.edit.text}
          </Popover.Item>
        )}
        {options.leave && (
          <Popover.Item
            state={isUpdatePeriod ? 'disabled' : 'active'}
            icon="LeaveClassIcon"
            onSelectItem={options.leave.onClick}
          >
            {options.leave.text}
          </Popover.Item>
        )}
        {options.delete && (
          <Popover.Item
            onSelectItem={options.delete.onClick}
            state={students === 0 && !isUpdatePeriod ? 'active' : 'disabled'}
            icon="DeleteIcon"
            subtitle={students === 0 ? undefined : options.delete.subtitle}
          >
            {options.delete.text}
          </Popover.Item>
        )}
        {options.unsynchronize && (
          <Popover.Item
            onSelectItem={options.unsynchronize.onClick}
            state={isUpdatePeriod ? 'disabled' : 'active'}
            icon="DeleteIcon"
            subtitle={options.unsynchronize.subtitle}
          >
            {options.unsynchronize.text}
          </Popover.Item>
        )}
      </div>
    </Popover>
  );
}

export { ClassCardOptions };
export type { ClassCardOptionsProps };
