import styled from '@emotion/styled';
import { Typography } from '../typography';
import { InnerHtml } from '../inner-html';
import { Icon } from '../icon';
import { useLayoutEffect, useRef, useState } from 'react';

const ResourceText = styled.div<{ hasStars: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: ${({ hasStars }) => (hasStars ? 'auto' : '62px')};
`;

const Title = styled(Typography.Subtitle2)`
  display: inline;

  div {
    display: inline;
  }
`;

const Description = styled(Typography.Body3)<{ oneLine: boolean }>`
  opacity: 0;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ oneLine }) => (oneLine ? 1 : 2)};
  line-clamp: ${({ oneLine }) => (oneLine ? 1 : 2)};
  -webkit-box-orient: vertical;
  word-wrap: break-word;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    opacity: 1;
  }
`;

const NewTag = styled.span`
  font-family: 'Roboto';
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;

  background-color: ${({ theme }) =>
    theme.tokens.color.specific.element.new.value};
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-inverted'].value};
  padding: 8px 4px;
  border-radius: 4px;

  height: 12px;
  width: fit-content;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin-right: 8px;
  transform: translateY(-2px);
`;

const StarsWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 8px;
`;

const EmptyStar = styled(Icon)`
  path {
    fill: ${({ theme }) =>
      theme.tokens.color.alias.cm.icon['icon-disabled'].value};
  }
`;

const FilledStar = styled(Icon)`
  path {
    fill: ${({ theme }) =>
      theme.tokens.color.specific.element['score-star'].value};
  }
`;

type Props = {
  title: string;
  description: string;
  isNew: boolean;
  stars?: number | null;
  t: (key: string, fallback: string) => string;
};

const renderStars = (stars: number): JSX.Element[] => {
  const starsArray = Array.from({ length: 3 }, (_, index) => {
    if (index < stars) {
      return <FilledStar key={index} icon="ScoreIcon" />;
    }
    return <EmptyStar key={index} icon="ScoreIcon" />;
  });

  return starsArray;
};

export function ResourceCardText({
  title,
  description,
  isNew,
  stars,
  t,
}: Props): JSX.Element {
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = useState<boolean>(false);

  useLayoutEffect(() => {
    const element = textContainerRef.current?.firstElementChild as HTMLElement;

    if (element) {
      const { offsetHeight } = element;
      const computedStyle = getComputedStyle(element);
      const lineHeight = parseInt(computedStyle.lineHeight, 10);
      setHasOverflow(offsetHeight > lineHeight);
    }
  }, [textContainerRef.current]);

  return (
    <ResourceText
      ref={textContainerRef}
      hasStars={Boolean(stars) || stars === 0}
    >
      <Title>
        {isNew && (
          <NewTag role="banner">{t('contents.resource.new', 'nuevo')}</NewTag>
        )}
        <InnerHtml text={title} />
      </Title>
      {stars !== undefined && (
        <StarsWrapper>{renderStars(stars || 0)}</StarsWrapper>
      )}
      <Description className="description" oneLine={hasOverflow}>
        <InnerHtml text={description} />
      </Description>
    </ResourceText>
  );
}
