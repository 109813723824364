import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import {
  Button,
  Password,
  TableComponent,
  Typography,
  useColumnsCustomRenderConfig,
  type CustomColumnRenderConfig,
} from '@innovamat/glimmer-components';

import { formatDate } from '../../utils/date';

import { type TestRow } from '../../tests-and-results';

import { EllipsisCell } from '../../../../components/common-table-components/ellipsis-cell';
import { TestReportCell } from './components/test-report-cell/test-report-cell';
import { TestStatusCell } from './components/test-status-cell/test-status-cell';
import { useTestsAndResultsColumnsConfiguration } from './hooks/use-tests-and-results-columns-configuration';
import { MockExamsDrawer } from '../mock-exams-drawer/mock-exams-drawer';
import { useMockExams } from '../mock-exams-drawer/context/mock-exams-provider';
import { useFlag } from '@innovamat/flags';
import { useUser } from '@innovamat/ga-features';

export const Container = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1rem;
`;

const TextWithButtonCell = styled(Typography.Body1)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .open-drawer-button {
    display: none;
  }
`;

type Props = {
  rows: TestRow[];
};

const customColumnsRenderConfig = (
  t: TFunction,
  openTestDrawer: (rowId: string) => void
): CustomColumnRenderConfig<TestRow> => ({
  rowName: (_, row) => (
    <TextWithButtonCell>
      <EllipsisCell
        key={`name_${row.classroomTestRoundId}`}
        text={row.rowName}
      />
      <Button
        key={`open-drawer-button_${row.classroomTestRoundId}`}
        onClick={() => openTestDrawer(row.classroomTestRoundId)}
        className="open-drawer-button"
        leftIcon="SideDrawerIcon"
        variant="secondary"
        size="S"
      >
        {t('common.open')}
      </Button>
    </TextWithButtonCell>
  ),
  status: (_, row) => (
    <TestStatusCell
      key={`status_${row.classroomTestRoundId}`}
      status={row.status}
    />
  ),
  password: (_, row) => (
    <Password
      key={`password_${row.classroomTestRoundId}`}
      value={row.password}
    />
  ),
  startDate: (_, row) => formatDate(row.startDate),
  endDate: (_, row) => formatDate(row.endDate),
  report: (_, { classroomTestRoundId, roundName, report }) => (
    <TestReportCell
      key={`report_${classroomTestRoundId}`}
      testRoundId={classroomTestRoundId}
      testRoundName={roundName}
      report={report}
    />
  ),
});

const TestsAndResultsTable = ({ rows }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useUser();

  const { value: showMockExamsDetailButton } = useFlag(
    'mockExamsDetail',
    false,
    {
      identifier: '',
      email: user?.email ?? '',
      custom: {},
    }
  );

  const { mockExamRowIds, onOpenDrawer } = useMockExams();

  const columnsWithoutRender = useTestsAndResultsColumnsConfiguration();
  const columns = useColumnsCustomRenderConfig<TestRow>(
    customColumnsRenderConfig(t, onOpenDrawer),
    columnsWithoutRender
  );

  return (
    <Container>
      <TableComponent
        columns={columns}
        id="tests-and-results-table"
        key="tests-and-results-table"
        clickableRows={showMockExamsDetailButton ? mockExamRowIds : []}
        rows={rows}
      />
      <MockExamsDrawer key="mock-test-drawer" />
    </Container>
  );
};

export { TestsAndResultsTable };
