type Props = {
  base64: string;
  name: string;
};

function base64toCSVFile({ base64, name }: Props): void {
  try {
    const csvBase64 = `data:application/csv;base64,${base64}`;
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;
    const fileName = `${name}_${formattedDate}.csv`;
    const link = document.createElement('a');
    link.href = csvBase64;
    link.download = fileName;
    link.click();
  } catch (error) {
    console.error('Error downloading file', error);
  }
}
export { base64toCSVFile };
