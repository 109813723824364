import i18next from 'i18next';

export const EMPTY_CELL_VALUE = '-';

export const scoreLevels = {
  LOW_SCORE: 50,
  MEDIUM_SCORE: 70,
  MEDIUM_HIGH_SCORE: 90,
};

export const getFixedLocalizedScore = (
  score?: number | null,
  fixedTo = 1
): string => {
  if (score === null || score === undefined) {
    return EMPTY_CELL_VALUE;
  }

  const locale = i18next.language.replace('_', '-');

  return score.toLocaleString(locale, {
    minimumFractionDigits: fixedTo,
    maximumFractionDigits: fixedTo,
  });
};

export const getColorToken = (score?: number | null): string => {
  if (score === null || score === undefined) {
    return 'no-data';
  }

  if (score >= scoreLevels.MEDIUM_HIGH_SCORE) {
    return 'super-high';
  }

  if (score >= scoreLevels.MEDIUM_SCORE) {
    return 'high';
  }

  if (score >= scoreLevels.LOW_SCORE) {
    return 'medium';
  }

  return 'low';
};
