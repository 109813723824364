import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useGetModulesOfCourseQuery } from '@innovamat/glow-api-client';
import { usePrint } from '@innovamat/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';
import { PrintFooter } from '../print-guides';
import {
  ActivityCard,
  ActivityGrid,
  ActivityImage,
  ActivityInfo,
  Bold,
  FileDescription,
  FileTitle,
  GlobalStyle,
  HeaderBar,
  ModuleName,
  StandardInfo,
  StandardsContainer,
  StandardSection,
  Stretch,
  StyledTable,
  TabletTitle,
  TBodyCell,
  THeadCell,
  UnlockedAppletsTitle,
  Wrapper,
} from './print-us-styled';

type Activity = {
  codenamepack: string;
  title: string;
  screenshot: string;
};

type Standard = {
  code: string;
  description: string;
  activities: Activity[];
};

type Module = {
  stretch: string;
  title: string;
  standards: Standard[];
};

const Description =
  '<p>Practice the Mathematical <b>Content Standards of the module</b> to strengthen fluency.</p><p>Students work independently, one student per device, focusing on the contents from the module. Part of the session is reserved for students to practice previous grade level standards based on individual needs.</p><p>Remember that you can check your students’ progress in the <b>Weekly Progress Report</b>.</p>';

const PrintModule: React.FC<Module> = ({ stretch, title, standards }) => {
  const { t } = useTranslation();

  return (
    <StyledTable>
      <thead>
        <tr>
          <THeadCell colSpan={1}>
            <HeaderBar className="header-bar">
              <FileTitle>
                {t('personalizedPractice.title', {
                  defaultValue: 'Personalized Practice',
                })}
              </FileTitle>
              <TabletTitle>
                <Stretch>{t(stretch)}</Stretch>
                <ModuleName> - {t(title)}</ModuleName>
              </TabletTitle>
            </HeaderBar>
          </THeadCell>
        </tr>
      </thead>
      <tbody>
        <tr>
          <TBodyCell>
            <FileDescription>
              <Trans
                i18nKey={t('personalizedPractice.description')}
                defaults={Description}
                components={{
                  b: <Bold />,
                  p: <p />,
                }}
              />
            </FileDescription>

            <UnlockedAppletsTitle>
              {t('personalizedPractice.unlockedApplets', {
                defaultValue: 'Unlocked Applets in this module:',
              })}
            </UnlockedAppletsTitle>
            <StandardsContainer>
              {standards.map(({ code, description, activities }, idx) => (
                <StandardSection key={idx} className="standard-section">
                  <StandardInfo>
                    <Bold>{code}</Bold>: {t(description, { lng: 'en' })}
                  </StandardInfo>
                  <ActivityGrid>
                    {activities.map(
                      ({ codenamepack, title, screenshot }, activityIndex) => (
                        <ActivityCard key={activityIndex}>
                          <ActivityImage src={screenshot} alt={title} />
                          <ActivityInfo>
                            <Bold>{codenamepack}</Bold> -{' '}
                            {t(title, { lng: 'en' })}
                          </ActivityInfo>
                        </ActivityCard>
                      )
                    )}
                  </ActivityGrid>
                </StandardSection>
              ))}
            </StandardsContainer>
          </TBodyCell>
        </tr>
      </tbody>
    </StyledTable>
  );
};

export function PrintPersonalizedPractice(): EmotionJSX.Element {
  const { courseId } = useParams();
  const { data } = useGetModulesOfCourseQuery({
    courseUuid: courseId || '',
  });
  const { componentRef, onPrint, loading } = usePrint(
    `Personalized Practice - ${courseId}`
  );

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <PrintFooter onPrint={onPrint} loading={loading} />
        <div ref={componentRef}>
          {data?.modulesOfCourse.map((module, moduleIndex) => (
            <PrintModule key={moduleIndex} {...module} />
          ))}
        </div>
      </Wrapper>
    </>
  );
}
