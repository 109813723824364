import styled from '@emotion/styled';
import { useRegionParameters } from '@innovamat/ga-features';
import { t } from 'i18next';
import type { SmartGuidesProviderProps } from '../../context/SmartGuidesProvider';
import { SmartGuidesProvider } from '../../context/SmartGuidesProvider';
import { replaceLocalization } from '../../utils/htmlParser/replace-localization/replace-localization';
import { PartsPrintable } from '../Parts/PartsPrintable';
import { SummarySectionPrintable } from '../SummarySection/SummarySectionPrintable';
import { TitlePrintable } from '../TitlePrintable';

const Wrapper = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.dark['02']};
  margin: 0 auto;
  max-width: 1240px;

  @media print {
    padding: 0;
    border: none;
  }
`;

const Printable = styled.div`
  width: 100%;

  .printableContent {
    column-count: 2;
    column-fill: balance;

    @media print {
      column-fill: auto;
    }
  }

  #prepr-video {
    display: none;
  }
`;

type Props = Omit<SmartGuidesProviderProps, 'children'>;

export function SmartGuidePrintable(props: Props): JSX.Element {
  const { session, smartGuide } = props;
  const { regionParameters } = useRegionParameters();

  return (
    <SmartGuidesProvider {...props}>
      <Wrapper>
        <Printable>
          <TitlePrintable
            title={session.title}
            subtitle={replaceLocalization(
              smartGuide.subtitle,
              t,
              regionParameters
            )}
          />

          <div className="printableContent">
            <SummarySectionPrintable />
            <PartsPrintable />
          </div>
        </Printable>
      </Wrapper>
    </SmartGuidesProvider>
  );
}
