import { useTranslation } from 'react-i18next';

import {
  type Organization,
  useOrganizationQuery,
} from '@innovamat/glow-api-client';
import { useUser } from '../../user-management';

const getNextAcademicYearId = (
  currentAcademicYearId: Organization['academicYearId']
): Organization['academicYearId'] => {
  const [regionId, year] = currentAcademicYearId.split('_');
  const nextAcademicYearId = `${regionId}_${Number(year) + 1}`;
  return nextAcademicYearId;
};

function useOrganization() {
  const { t } = useTranslation();
  const { user } = useUser();
  const { data: { organization } = {}, isLoading } = useOrganizationQuery(
    { id: user?.organizationId! },
    { enabled: !!user?.organizationId, staleTime: Infinity }
  );

  const mapTranslation = (lang: string | null) => ({
    value: lang!,
    label: t(`common.language.${lang}`),
  });

  const availableLanguages =
    organization?.availableLanguages?.map(mapTranslation);

  const isOrgAnonymized = organization?.anonymized ?? false;

  return {
    organization,
    loading: isLoading,
    availableLanguages,
    isOrgAnonymized,
  };
}

export { useOrganization, getNextAcademicYearId };
