import styled from '@emotion/styled';
import { Accordion, Typography } from '@innovamat/glimmer-components';
import { usePartColors } from '../../hooks/usePartColors';
import { IconPart } from '../IconPart';
import type { PartType } from '../../types/SmartGuide';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import type { Dimension } from '../../types/Dimension';
import { IconEvaluable } from '../IconEvaluable';
import { KeyMoment } from '../KeyMoment/KeyMoment';
import { css } from '@emotion/react';
import type { IconType } from '@innovamat/glimmer-icons';
import { PreprInnerHtml } from '../PreprInnerHtml';

type CollapsablePartProps = {
  part: PartType;
  evaluable?: {
    isEvaluable: boolean;
    dimension?: Dimension;
  };
  momentTitle: string;
  activityTitle: string;
  activityTitleExtra?: string;
  children: React.ReactNode;
  isKeyMoment?: boolean;
  partIcon?: IconType;
  hasSupport?: boolean;
  hasExtension?: boolean;
} & AccordionProps;

type AccordionProps = {
  isExpanded: boolean;
  onExpand: (value: boolean) => void;
};

const Summary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-start;
`;

const Title = styled.div<{ isExpanded?: boolean }>`
  flex-direction: column;
  justify-content: center;

  ${({ isExpanded }) => css`
    display: ${isExpanded ? 'flex' : 'inline-grid'};
  `}
`;

const ActivityTitle = styled(Typography.Body1)<{ isExpanded?: boolean }>`
  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;

const ActivityTitleExtra = styled(Typography.Body2)`
  display: inline;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const KeyMomentContainer = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;

  > span {
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export function CollapsablePart({
  part,
  evaluable,
  momentTitle,
  activityTitle,
  activityTitleExtra,
  children,
  isExpanded,
  onExpand,
  isKeyMoment,
  partIcon,
}: CollapsablePartProps): JSX.Element {
  const { stage } = useSmartGuides();
  const { getElement } = usePartColors();

  const getSummary = (): JSX.Element => {
    return (
      <Summary>
        {isKeyMoment && (
          <KeyMomentContainer>
            <KeyMoment part={part} />
          </KeyMomentContainer>
        )}
        <TitleContainer>
          <IconPart icon={partIcon} part={part} stage={stage} />
          <Title isExpanded={isExpanded}>
            <Typography.SuperTitle color={getElement(part)}>
              {momentTitle}
            </Typography.SuperTitle>

            <ActivityTitle isExpanded={isExpanded}>
              <PreprInnerHtml text={activityTitle} />

              {activityTitleExtra && (
                <ActivityTitleExtra>
                  &nbsp;
                  <PreprInnerHtml text={activityTitleExtra} />
                </ActivityTitleExtra>
              )}
            </ActivityTitle>
          </Title>
        </TitleContainer>

        {evaluable?.isEvaluable && (
          <IconEvaluable dimension={evaluable.dimension} />
        )}
      </Summary>
    );
  };

  return (
    <Accordion
      isExpanded={isExpanded}
      onExpand={onExpand}
      summary={getSummary()}
    >
      <StyledWrapper onClick={(e) => e.stopPropagation()}>
        {children}
      </StyledWrapper>
    </Accordion>
  );
}
