import styled from '@emotion/styled';
import { ERROR_TYPE, ErrorPage } from '@innovamat/ga-features';
import { Container, LeftWrapper } from '../layout/header';
import { Logo } from './logo';

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -106px;
  height: calc(100% - 64px);
`;

const Wrapper = styled.div`
  height: 100vh;
`;

export function NotFound() {
  return (
    <Wrapper>
      <Container>
        <LeftWrapper>
          <Logo />
        </LeftWrapper>
      </Container>
      <Content>
        <ErrorPage errorType={ERROR_TYPE.NOT_FOUND} />
      </Content>
    </Wrapper>
  );
}
