import {
  LogoInnovamat,
  RegisterBg,
  UEFlag,
  WavesBottomBg,
  WavesTopBg,
} from '@innovamat/glimmer-assets';

import {
  Dropdown,
  GridCol,
  GridHidden,
  GridRow,
  Icon,
  Icon as IconGlimmer,
  ImageComponent,
} from '@innovamat/glimmer-components';
import { useQueryParams } from '@innovamat/hooks';
import {
  getRegisterLanguagesByRegion,
  ROOT_REGIONS,
  RootRegion,
} from '@innovamat/localization';
import {
  addParamToUrl,
  addParamToUrlAndRefresh,
} from '@innovamat/radiance-utils';
import { isUSRegion } from '@innovamat/regional-config-variables';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-domv6';
// import UEFlag from '../../assets/images/ue-flag.png';

import styled from '@emotion/styled';
import { useEnvVariables } from '../../../env-variables-manager/env-variables-provider';
import { useGetGeoLocation } from '../../hooks/use-geolocation';
import { useLanguage } from '../../hooks/use-language';
import { useGetUserRegion } from '../../hooks/use-user-region';
import LanguageButton from './language-button';
import {
  CountryRegionButtonWrapper,
  Description,
  DropdownSelection,
  ErrorContainer,
  FromDataCenter,
  FromText,
  GoBackLink,
  GridColWithBg,
  GridContainerStyled,
  Header,
  LogoContainer,
  MainGridRow,
  RegisterImage,
  StyledNotification,
  Title,
  WavesBottom,
  WavesTop,
} from './login-register-layout.styles';

export type NotificationType =
  | {
      message: string | JSX.Element;
      type: 'error' | 'warning';
    }
  | undefined;

type Props = {
  isRegisterLayout?: boolean;
  isSuccess?: boolean;
  title?: string;
  description?: string;
  children: JSX.Element;
  error?: string | JSX.Element;
  canGoBack?: boolean;
  onSuccessBody?: JSX.Element;
  notification?: NotificationType;
  handleIsUSA?: (isUSA: boolean) => void;
  hasLanguageDropdown?: boolean;
};

function LoginRegisterLayout({
  isRegisterLayout,
  isSuccess,
  children,
  title,
  description,
  canGoBack,
  onSuccessBody,
  error,
  notification,
  handleIsUSA,
  hasLanguageDropdown,
}: Props) {
  const { t, i18n } = useTranslation();
  const params = useQueryParams();

  const { setLanguageFromUrl, setHtmlLanguage } = useLanguage();
  const { lng } = useParams<{ lng: string }>();
  const language = params.get('l') || params.get('language');
  const dataCenter = params.get('dc');
  const region = useGetUserRegion();
  const { CORPORATE_IMAGE_URL, GEOLOCATION_URL, ENVIRONMENT } =
    useEnvVariables();
  const getAvailableLanguages = (region: string) => {
    const languages = getRegisterLanguagesByRegion(region);

    // CHAPUZA PARA LA WEB CORPORATIVA https://innovamat.atlassian.net/browse/GAM-4661
    if (languages.includes('en_US')) {
      languages.push('en');
    }
    return languages;
  };

  const languages = getAvailableLanguages(region);

  const [country] = useState(region);

  const header = document.getElementById('headerLoginLayout');

  const { data: geoLocation, isSuccess: isGeoSuccess } = useGetGeoLocation();
  const defaultRegion = 'IN2';

  const [isPressed, setIsPressed] = useState(false);
  const [dataCenterRegion, setDataCenterRegion] = useState(dataCenter);

  const i18nLanguage = i18n.language;

  const setDataCenterRegionAndChangeUrl = (
    region: string,
    language?: string
  ) => {
    addParamToUrl('dc', region);
    addParamToUrlAndRefresh('l', language || i18n.language);
    setDataCenterRegion(region);
  };

  const handleSelectLanguage = async (languageLocale: string) => {
    addParamToUrl('l', languageLocale);
    await i18n.changeLanguage(languageLocale);
  };

  //TODO: FIX TEMPORAL
  useEffect(() => {
    if (isGeoSuccess) {
      const possibleRegion = geoLocation?.country || region;

      const countryToSet = ROOT_REGIONS.includes(possibleRegion as RootRegion)
        ? possibleRegion
        : defaultRegion;

      const languageToSet = getRegisterLanguagesByRegion(countryToSet)?.[0];

      if (!language && !lng) handleSelectLanguage(languageToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeoSuccess]);

  useEffect(() => {
    setLanguageFromUrl();
    if (lng) {
      handleSelectLanguage(lng);
      return;
    }
    const lang = languages.find((i) => i === language);
    if (lang) {
      handleSelectLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, lng]);

  setHtmlLanguage(i18nLanguage || 'es');

  const getDataCenterRegion = async (
    country: string,
    setDataCenterRegion: (region: string) => void
  ): Promise<void> => {
    if (!dataCenter) {
      const isUS = await isUSRegion(GEOLOCATION_URL);
      if (isUS) {
        setDataCenterRegion('USA');
        handleSelectLanguage('en');
        handleIsUSA?.(true);
      } else if (
        country.toLocaleUpperCase().includes('ES') ||
        country.toLocaleUpperCase().includes('IT')
      ) {
        setDataCenterRegion('Europe');
      } else {
        setDataCenterRegion('LATAM');
      }
    }
  };

  useEffect(() => {
    getDataCenterRegion(country, setDataCenterRegion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const showFlag = ENVIRONMENT === 'staging';

  return (
    <>
      <GridContainerStyled>
        <GridRow noGutters>
          <GridCol xs={12} sm={12} md={8} lg={8}>
            <GridRow id="headerLoginLayout" noGutters>
              <GridCol>
                <Header>
                  <LogoContainer>
                    <Link to="/">
                      <LogoInnovamat />
                    </Link>
                  </LogoContainer>
                  {hasLanguageDropdown && (
                    <CountryRegionButtonWrapper>
                      <LanguageButton
                        language={t(`language.${i18n.language}`)}
                        handleLanguageClick={handleSelectLanguage}
                      />
                    </CountryRegionButtonWrapper>
                  )}
                </Header>
              </GridCol>
            </GridRow>
            <MainGridRow
              justify="center"
              headerHeight={header?.clientHeight || 0}
              noGutters
            >
              <GridCol xs={12} sm={12} md={6} lg={6}>
                {isSuccess && onSuccessBody ? (
                  onSuccessBody
                ) : (
                  <>
                    {notification && (
                      <StyledNotification
                        type={notification.type}
                        canClose={false}
                        text={notification.message}
                      />
                    )}
                    {canGoBack && (
                      <GoBackLink to="/">
                        <Icon icon="BackIcon" /> {t('back.button')}
                      </GoBackLink>
                    )}

                    <>
                      {title && <Title>{title}</Title>}
                      {description && <Description>{description}</Description>}
                      <FromDataCenter descriptionExists={!!description}>
                        <FromText>
                          {t('login.datacenter.from', 'Desde')}:
                        </FromText>
                        <Dropdown closeOnSelectItem onToggle={setIsPressed}>
                          <Dropdown.Toggle>
                            <DropdownSelection isPressed={isPressed}>
                              <IconGlimmer size="S" icon="ArrangeIcon" />
                              {dataCenterRegion === 'USA' && (
                                <>
                                  <IconGlimmer size="L" icon="UsaIcon" />
                                  {t('login.datacenter.region.usa', 'EEUU')}
                                </>
                              )}
                              {dataCenterRegion === 'Europe' && (
                                <>
                                  <IconGlimmer size="L" icon="EuropeIcon" />
                                  {t(
                                    'login.datacenter.region.europe',
                                    'Europe'
                                  )}
                                </>
                              )}
                              {dataCenterRegion === 'LATAM' && (
                                <>
                                  <IconGlimmer size="L" icon="LatamIcon" />
                                  {t('login.datacenter.region.latam', 'LATAM')}
                                </>
                              )}
                            </DropdownSelection>
                          </Dropdown.Toggle>
                          <Dropdown.Content position="bottomLeft">
                            <Dropdown.Item
                              state="active"
                              icon="UsaIcon"
                              onSelectItem={() => {
                                setDataCenterRegionAndChangeUrl('USA', 'en');
                              }}
                            >
                              {t('login.datacenter.region.usa', 'EEUU')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              state="active"
                              icon="EuropeIcon"
                              onSelectItem={() => {
                                setDataCenterRegionAndChangeUrl('Europe');
                              }}
                            >
                              {t('login.datacenter.region.europe', 'Europe')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              state="active"
                              icon="LatamIcon"
                              onSelectItem={() => {
                                setDataCenterRegionAndChangeUrl('LATAM');
                              }}
                            >
                              {t('login.datacenter.region.latam', 'LATAM')}
                            </Dropdown.Item>
                          </Dropdown.Content>
                        </Dropdown>
                      </FromDataCenter>
                      <ErrorContainer>{error}</ErrorContainer>
                    </>
                    {children}
                  </>
                )}
              </GridCol>
            </MainGridRow>
          </GridCol>
          <GridHidden xs sm>
            <GridColWithBg
              isRegisterLayout={isRegisterLayout}
              xs={12}
              sm={12}
              md={4}
              lg={4}
            >
              {showFlag && <FlagLogo src={(UEFlag as any).src} alt="flag" />}

              {isRegisterLayout ? (
                <RegisterImage bg={(RegisterBg as any).src} />
              ) : (
                <>
                  <WavesTop>
                    <WavesTopBg width="100%" />
                  </WavesTop>
                  <WavesBottom>
                    <WavesBottomBg width="100%" />
                  </WavesBottom>
                </>
              )}
            </GridColWithBg>
          </GridHidden>
        </GridRow>
      </GridContainerStyled>
    </>
  );
}

const FlagLogo = styled(ImageComponent)`
  position: absolute;
  width: 323px;
  margin-left: 8px;
  z-index: 1;
  top: 8px;
`;

export default LoginRegisterLayout;
