import styled from '@emotion/styled';
import { getElevation } from '../elevation';

import { IconButton } from '../icon-button';
import { Typography } from '../typography';
import { ClassCardHeader } from './class-card.header';
import { ClassCardOptions } from './class-card.options';

const SIZE = {
  S: 'S',
  M: 'M',
} as const;

type Size = (typeof SIZE)[keyof typeof SIZE];

export type ClassCardProps = {
  avatar?: string;
  course: string;
  image?: string;
  isDisabled?: boolean;
  isImportedFromGoogle?: boolean;
  isUpdatePeriod?: boolean;
  name: string;
  onClick?: () => void;
  onOpenOptions?: () => void;
  options?: ClassCardOptions;
  size?: Size;
  students: number;
  t: (key: string) => string;
  teachers: string[];
  yearUpdateNextCourse?: string;
};

const Card = styled('div')<{ isDisabled?: boolean }>`
  ${({ isDisabled }) => isDisabled && `pointer-events: none;`}
  cursor: pointer;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  gap: 1.5rem;
  padding: 1rem;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};

  ${({ theme }) => getElevation(theme, 'elevation 2')};
  transition: box-shadow 0.1s ease-in-out;

  :hover {
    ${({ theme }) => getElevation(theme, 'elevation 4')};
  }
`;

const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TeachersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 3.75rem);
`;

const StudentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Caption = styled(Typography.Caption)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const Text = styled(Typography.Body3)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const YearUpdateText = styled(Text)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-accent'].value};
  font-weight: 500;
`;

const HeaderOptions = styled.div`
  display: flex;
  gap: 0.5rem;
  height: fit-content;
  align-items: center;
`;

const NoStudentsText = styled(Text)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text['text-error'].value};
`;

export function ClassCard({
  isDisabled,
  isUpdatePeriod,
  isImportedFromGoogle,
  options,
  size = SIZE.M,
  students,
  teachers,
  onClick,
  onOpenOptions,
  ...headerProps
}: ClassCardProps): JSX.Element {
  const { t, yearUpdateNextCourse } = headerProps;

  const getTeachers = (): JSX.Element => {
    const showTeachersNames = teachers?.length <= 2 && teachers?.length > 0;

    if (yearUpdateNextCourse && teachers?.length === 0) {
      return (
        <YearUpdateText>{t('classroom.updatePeriod.teachers')}</YearUpdateText>
      );
    }

    return showTeachersNames ? (
      <Text>{teachers.join(', ')}</Text>
    ) : (
      <Text>{teachers?.length}</Text>
    );
  };

  return (
    <Card onClick={onClick} isDisabled={isDisabled}>
      <ClassCardHeader
        {...headerProps}
        rightSide={
          <HeaderOptions>
            {isImportedFromGoogle && (
              <IconButton
                size="S"
                tooltipText={t('classroom.importedFromGoogleClassroom.tooltip')}
                icon="GoogleClassroomIcon"
              />
            )}
            <ClassCardOptions
              isUpdatePeriod={isUpdatePeriod}
              onOpenOptions={onOpenOptions}
              options={options}
              students={students}
            />
          </HeaderOptions>
        }
      />

      {size === SIZE.M && (
        <CardBody>
          <TeachersWrapper>
            <Caption>{t('class-card.teachers')}</Caption>
            {getTeachers()}
          </TeachersWrapper>
          <StudentsWrapper>
            <Caption>{t('class-card.students')}</Caption>
            {students === 0 ? (
              <NoStudentsText>
                {t('classroom.students.pending-to-add')}
              </NoStudentsText>
            ) : (
              <Text>{students}</Text>
            )}
          </StudentsWrapper>
        </CardBody>
      )}
    </Card>
  );
}
