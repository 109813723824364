import { useTimeFormatter } from './hooks/use-time-formatter';

type Props = {
  timeInSeconds: number;
  format: string;
};

const TimeFormatter = ({ timeInSeconds, format }: Props): JSX.Element => {
  const { days, hours, minutes, seconds } = useTimeFormatter({ timeInSeconds });

  if (!format) {
    return <>{timeInSeconds}</>;
  }

  const formattedTime = format
    .replace(/{d}/g, String(days).padStart(1, '0'))
    .replace(/{h}/g, String(hours).padStart(1, '0'))
    .replace(/{m}/g, String(minutes).padStart(1, '0'))
    .replace(/{s}/g, String(seconds).padStart(1, '0'));

  return <>{formattedTime}</>;
};

export { TimeFormatter };
