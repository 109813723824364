import { useTranslation } from 'react-i18next';
import { Chip, SEMANTIC_TYPE } from '@innovamat/glimmer-components';
import type { SemanticType } from '@innovamat/glimmer-components';

const TEST_STATUS = {
  STARTED: 'started',
  PLANNED: 'planned',
  COMPLETED: 'completed',
  NOT_STARTED: 'not_started',
} as const;

const getChipType = (status: string): SemanticType => {
  if (status === TEST_STATUS.STARTED) {
    return SEMANTIC_TYPE.INFO;
  }

  if (status === TEST_STATUS.PLANNED || status === TEST_STATUS.NOT_STARTED) {
    return SEMANTIC_TYPE.DEFAULT;
  }

  if (status === TEST_STATUS.COMPLETED) {
    return SEMANTIC_TYPE.SUCCESS;
  }

  return SEMANTIC_TYPE.DEFAULT;
};

const TestStatusCell = ({ status }: { status: string }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Chip
      type={getChipType(status)}
      text={t(`testsAndResultsView.statusTableCell.${status}`)}
    />
  );
};

export { TestStatusCell, TEST_STATUS };
