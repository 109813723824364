import { useCallback, useState } from 'react';
import { useCheckOverflow } from '../../hooks/use-check-overflow';
import styled from '@emotion/styled';
import { Typography } from '../typography';
import { Tooltip } from '../tooltip';

import { Icon } from '../icon';
import { ClassroomAvatar } from '../classroom-avatar';

export type ClassCardHeaderProps = {
  avatar?: string;
  name: string;
  course?: string;
  yearUpdateNextCourse?: string;
  rightSide?: JSX.Element;
  className?: string;
  noImg?: boolean;
  t?: (key: string) => string;
};

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
`;

const HeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
`;

const HeaderText = styled.div`
  max-height: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`;

const Title = styled(Typography.Subtitle1)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
`;

const Subtitle = styled(Typography.Body3)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: -2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const UpdatePeriodText = styled(Typography.Body3)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-accent'].value};
  font-weight: 500;
`;

export function ClassCardHeader({
  avatar,
  name,
  course,
  yearUpdateNextCourse,
  rightSide,
  noImg,
  t,
}: ClassCardHeaderProps): JSX.Element {
  const [headerTextRef, setHeaderTextRef] = useState<HTMLElement | null>(null);

  const onSetHeaderTextRef = useCallback((ref: HTMLElement | null) => {
    setHeaderTextRef(ref);
  }, []);

  const { hasOverflow: titleHasOverflow } = useCheckOverflow({
    text: headerTextRef?.querySelector('.title'),
    container: headerTextRef,
    dependencies: [onSetHeaderTextRef],
  });

  return (
    <CardHeader>
      <HeaderLeftSide>
        {!noImg && (
          <Tooltip
            content={
              yearUpdateNextCourse ? t?.('classroom.updatePeriod.tooltip') : ''
            }
          >
            <ClassroomAvatar
              avatar={avatar}
              status={yearUpdateNextCourse ? 'imported' : undefined}
            />
          </Tooltip>
        )}
        <HeaderText ref={onSetHeaderTextRef}>
          <Tooltip content={titleHasOverflow ? name : ''}>
            <Title className="title">{name}</Title>
          </Tooltip>
          <Subtitle>
            {yearUpdateNextCourse ? (
              <>
                {yearUpdateNextCourse}
                <Icon icon="NextWithTextIcon" size="S" />
                <UpdatePeriodText>{course}</UpdatePeriodText>
              </>
            ) : (
              course
            )}
          </Subtitle>
        </HeaderText>
      </HeaderLeftSide>

      {rightSide}
    </CardHeader>
  );
}
