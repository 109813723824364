import { Dropdown, Icon } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import {
  CountryLanguage,
  CountryLanguageTexts,
  CountrySelected,
  Language,
} from './language-button.styles';

type LanguageButtonProps = {
  language: string;
  isMobile?: boolean;
  handleLanguageClick: (languageLocale: string) => Promise<void>;
};

function LanguageButton({
  language,
  handleLanguageClick,
}: LanguageButtonProps) {
  const { t } = useTranslation();

  return (
    <Dropdown closeOnSelectItem>
      <Dropdown.Toggle>
        <CountryLanguage data-testid="country-selector">
          <CountrySelected>
            <Icon size="S" icon="LanguageIcon" />
            <CountryLanguageTexts>
              <Language>{language}</Language>
              <Icon size="S" icon="ArrangeIcon" />
            </CountryLanguageTexts>
          </CountrySelected>
        </CountryLanguage>
      </Dropdown.Toggle>
      <Dropdown.Content position="bottomLeft">
        <Dropdown.Item
          state="active"
          onSelectItem={() => {
            handleLanguageClick('es');
          }}
        >
          {t('language.es')}
        </Dropdown.Item>
        <Dropdown.Item
          state="active"
          onSelectItem={() => {
            handleLanguageClick('ca');
          }}
        >
          {t('language.ca')}
        </Dropdown.Item>
        <Dropdown.Item
          state="active"
          onSelectItem={() => {
            handleLanguageClick('va');
          }}
        >
          {t('language.va')}
        </Dropdown.Item>
        <Dropdown.Item
          state="active"
          onSelectItem={() => {
            handleLanguageClick('eu');
          }}
        >
          {t('language.eu')}
        </Dropdown.Item>
        <Dropdown.Item
          state="active"
          onSelectItem={() => {
            handleLanguageClick('en');
          }}
        >
          {t('language.en')}
        </Dropdown.Item>
        <Dropdown.Item
          state="active"
          onSelectItem={() => {
            handleLanguageClick('it_IT');
          }}
        >
          {t('language.it_IT')}
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown>
  );
}

export default LanguageButton;
