import styled from '@emotion/styled';

import { Typography } from '@innovamat/glimmer-components';

import { useRegionalNumber } from '@innovamat/ga-features';
import { EMPTY_CELL_VALUE } from '../../../../utils';

type StatementCellProps = {
  isCorrect?: boolean;
  answer?: string;
};

const StatementCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledText = styled(Typography.Subtitle2)<{ hasNoAnswer: boolean }>`
  color: ${({ hasNoAnswer, theme }) =>
    hasNoAnswer
      ? theme.tokens.color.specific.reports.semantic['no-data'].value
      : 'inherit'};
`;

const BottomLine = styled.div<{ hasNoAnswer: boolean; isCorrect?: boolean }>`
  margin-top: 2px;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: ${({ hasNoAnswer, isCorrect, theme }) => {
    if (hasNoAnswer) {
      return theme.tokens.color.specific.reports['progress-bar'].empty.value;
    }
    return isCorrect
      ? theme.tokens.color.specific.reports.semantic.high.value
      : theme.tokens.color.specific.reports.semantic.low.value;
  }};
`;

export function StatementCell({
  isCorrect,
  answer,
}: StatementCellProps): JSX.Element {
  const hasNoAnswer = !answer;
  const numericValue = answer ? parseInt(answer) : undefined;
  const regionalNumber = useRegionalNumber({
    number: !isNaN(numericValue as number) ? numericValue : undefined,
  });

  const formatAnswer = (answer: string | undefined): string => {
    if (!answer) return EMPTY_CELL_VALUE;

    if (!isNaN(parseInt(answer))) {
      return regionalNumber?.toLowerCase() ?? EMPTY_CELL_VALUE;
    }

    return answer.toLowerCase();
  };

  return (
    <StatementCellContainer>
      <StyledText hasNoAnswer={hasNoAnswer}>{formatAnswer(answer)}</StyledText>
      <BottomLine hasNoAnswer={hasNoAnswer} isCorrect={isCorrect} />
    </StatementCellContainer>
  );
}
