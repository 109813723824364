import type { Region } from '@innovamat/localization';
import type { StandardContent } from '@innovamat/resource-viewer';
import type { ContentBlockTag, StandardTag } from '../types/SmartGuide';
import { Stage } from '../types/Stage';
import {
  getStandardBlocks,
  getStandardTags,
  hasContentBlocks,
} from '../utils/standards';

type Props = {
  data: any;
  stage: Stage;
  standardContent?: StandardContent[];
  region?: Region;
};

export function mapNewStandardContent({
  data,
  stage,
  standardContent,
  region,
}: Props): ContentBlockTag[] | StandardTag[] {
  // create standards from BBDD | can be "standard" or "content block"

  if (standardContent) {
    const hasContentBlocksToDisplay = hasContentBlocks(standardContent);
    if (hasContentBlocksToDisplay) {
      const blocks = getStandardBlocks(standardContent);

      if (blocks.length > 0) {
        return blocks;
      }
    }
    const standards = getStandardTags(standardContent);

    if (standards.length > 0) {
      return standards;
    }
  }

  const isUSEdr = region === 'US-EDR';

  // return the old way, add here de logic for the different regions, and remove login from the main component

  if (data.bloques_de_contenido_resaltado && !isUSEdr) {
    return Object.entries(data.bloques_de_contenido_resaltado).map(
      ([key, value]) => {
        const isSecondaryNumAndOps =
          stage === Stage.Secondary && key === 'numbers_and_operations';

        return {
          textKey: isSecondaryNumAndOps
            ? `digitalguides.blocks.${key}.se`
            : `digitalguides.blocks.${key}`,
          isEnabled: value as boolean,
          isFromPrepr: true,
        } as ContentBlockTag;
      }
    );
  }

  if (data.us_standards && isUSEdr) {
    return data.us_standards?.map((standard: any) => ({
      text: standard.body,
      tooltipKey: undefined,
      isFromPrepr: true,
    })) satisfies StandardTag[];
  }

  return [];
}
