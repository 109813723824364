import type { RegionParameters } from '@innovamat/glow-api-client';
import { useRegionParameters } from '../../contents/hooks/use-region-parameters';

type Props = {
  number?: number | null;
  regionParameters?: RegionParameters;
};

const useRegionalNumber = ({ number, regionParameters }: Props): string | undefined => {
  const { regionParameters: fetchedRegionParameters } = useRegionParameters();

  const parameters = regionParameters ?? fetchedRegionParameters;

  const thousandSeparator = parameters?.thousandSeparator || ' ';
  const decimalSeparator = parameters?.decimalSeparator || ',';
  const divisionSign = parameters?.shortDivisionSign || '/';

  return number !== undefined && number !== null
    ? number
        .toString()
        .replace(/,/g, thousandSeparator)
        .replace(/\./g, decimalSeparator)
        .replace(/\//g, divisionSign)
    : undefined;
};

export { useRegionalNumber };
