import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GeneralProgressHeader from './general-progress.header';
import { getGeneralColumns } from './utils/get-general-columns';
import { Modal } from '@innovamat/glimmer-components';
import {
  LastYearReportQuery,
  ProgressReports,
  useLastYearReportQuery,
  Course,
  useProgressReportsQuery,
} from '@innovamat/glow-api-client';
import {
  getProgressStudentsRows,
  preprocessProgressReport,
} from '../../utils/preprocess';
import { Stage } from '@innovamat/radiance-utils';
import useDownloadReports from '../../hooks/use-download-reports';
import { renderTables } from '../../utils/render-tables';
import { isNullOrUndefined } from '../../utils/common';
import { ERROR_TYPE, ErrorPage, useOrganization } from '@innovamat/ga-features';

type GeneralReportsQuery = {
  progressReports: ProgressReports;
};

type Props = {
  isSecundaria: boolean;
  classroomId: string;
  setEventData: (eventType: string, eventProperties?: any) => void;
  classroomName: string;
  educationalStage: Stage;
  courses: Course[];
  standard: string | null;
  handleNavigate: (url: string, queryParams?: string) => void;
  handleNavigateToResource: (appletId: string) => void;
  isLastYearReport?: boolean;
  isPrimary: boolean;
  lastYearReportBannerFlag?: boolean;
  hasLastYearReport?: boolean | string;
};

export type ReportDate = {
  from: string;
  to: string;
};

export function GeneralProgress({
  isSecundaria,
  classroomId,
  setEventData,
  classroomName,
  educationalStage,
  courses,
  standard,
  handleNavigate,
  handleNavigateToResource,
  isLastYearReport,
  isPrimary,
  hasLastYearReport,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [expandedColumn, setExpandedColumn] = useState({});
  const [standardStructure, setStandardStructure] = useState<string | null>(
    standard
  );

  useEffect(() => {
    handleSendEvent('general_dashboard_access');
  }, []);

  const stage = isSecundaria ? 'secondary' : 'primary';

  const [reportDate, setReportDate] = useState<ReportDate>({
    from: '',
    to: '',
  });

  const [initialDate, setInitialDate] = useState<ReportDate>({
    from: '',
    to: '',
  });

  const [fetchData, setFetchData] = useState({
    id: classroomId,
    from: '',
    to: '',
    stage,
    standardStructure: !isSecundaria && standard ? standard : '',
  });

  const { data, isLoading } = useProgressReportsQuery<GeneralReportsQuery>(
    {
      ...fetchData,
      standardStructure: !isSecundaria && standard ? standard : '',
    },
    {
      enabled: !isLastYearReport && standard !== null,
    }
  );

  const { data: lastYearData, isLoading: isLastYearDataLoading } =
    useLastYearReportQuery<LastYearReportQuery>(
      {
        id: classroomId,
        standardStructure: !isSecundaria && standard ? standard : '',
      },
      {
        enabled: !isSecundaria && isLastYearReport,
      }
    );

  const lastYearReportStartDate = lastYearData?.lastYearReport?.fromDate || '';
  const lastYearReportEndDate = lastYearData?.lastYearReport?.toDate || '';

  useEffect(() => {
    if (data?.progressReports) {
      const dateFrom = data.progressReports?.dateFrom || '';
      const dateTo = data.progressReports?.dateTo || '';
      setReportDate({
        from: dateFrom,
        to: dateTo,
      });
      if (initialDate.from === '' && initialDate.to === '') {
        setInitialDate({
          from: dateFrom,
          to: dateTo,
        });
      }
    }
  }, [data?.progressReports]);

  const handleSendEvent = (event: string, parameters?: {}) => {
    setEventData(event, {
      classroom_id: classroomId,
      dashboard_tab: 'GeneralProgress',
      from: reportDate.from,
      to: reportDate.to,
      report_week: '',
      educational_stage: educationalStage,
      ...parameters,
    });
  };

  const handleDateChange = (date: Date, queryParam: 'from' | 'to') => {
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.getTime() - timezoneOffset);
    const dateISO = adjustedDate.toISOString().split('.')[0];

    handleSendEvent('report_period_change', {
      [queryParam]: dateISO,
    });

    setFetchData((prevReportDate) => ({
      ...prevReportDate,
      from: prevReportDate.from === '' ? reportDate.from : prevReportDate.from,
      to: prevReportDate.to === '' ? reportDate.to : prevReportDate.to,
      [queryParam]: dateISO,
    }));

    setReportDate((prevReportDate) => ({
      ...prevReportDate,
      from: prevReportDate.from === '' ? reportDate.from : prevReportDate.from,
      to: prevReportDate.to === '' ? reportDate.to : prevReportDate.to,
      [queryParam]: dateISO,
    }));
  };

  const handleClickDataPoint = (dataPoint: { from: string; to: string }) => {
    const searchParams = `?from=${dataPoint.from}&to=${dataPoint.to}`;
    handleNavigate('/reports/weekly-results', searchParams);
  };

  const reports = isLastYearReport
    ? lastYearData?.lastYearReport?.reports
    : data?.progressReports.reports;

  const mainReport = reports?.[0];
  const adaptedReports = reports?.slice(1);

  const loading = isLastYearReport ? isLastYearDataLoading : isLoading;

  const { organization } = useOrganization();
  const isUs = organization?.region.startsWith('US');

  const mainReportData = {
    columns: getGeneralColumns({
      t,
      handleClickDataPoint,
      expandedColumn: expandedColumn[mainReport?.id || ''],
      isSecundaria,
      averageReport: mainReport?.average,
      handleSendEvent,
      handleNavigateToResource,
      isLastYearReport,
      isUs,
    }),
    rows: getProgressStudentsRows(mainReport),
    id: mainReport?.id || '',
    averageRow: preprocessProgressReport(mainReport?.average),
    courseOrder: mainReport?.courseOrder,
  };

  const adaptedReportsData = adaptedReports?.map((adaptedReport: any) => {
    const id = adaptedReport?.id;
    const columns = getGeneralColumns({
      t,
      handleClickDataPoint,
      expandedColumn: expandedColumn[id],
      isSecundaria,
      averageReport: adaptedReport?.average,
      handleSendEvent,
      handleNavigateToResource,
      isLastYearReport,
      isUs,
    });
    const rows = getProgressStudentsRows(adaptedReport);
    const averageRow = preprocessProgressReport(adaptedReport?.average);
    const courseOrder = adaptedReport?.courseOrder;
    return { columns, rows, id, averageRow, courseOrder };
  });

  const onExpandColumn = (column: string, tableId: string): void => {
    setExpandedColumn((prevState) => ({
      ...prevState,
      [tableId]: column,
    }));
    handleSendEvent(column ? 'expand_column' : 'contract_column', {
      column_expanded: column,
    });
  };

  const {
    handleDownload,
    googleDriveLoading,
    openGoogleDriveFile,
    googleDriveUrl,
    onCloseModalGoogleDriveModal,
  } = useDownloadReports({
    educationalStage,
    classroomName,
    studentsRows: mainReportData.rows,
    averageRow: mainReportData.averageRow,
    adaptedReportsData,
    reportDate,
    handleSendEvent,
    reportType: 'general',
    courses,
  });

  const checkReportHasData = (students: any) => {
    return students?.some(
      (report: any) => !isNullOrUndefined(report.averageScore)
    );
  };

  function getReportDate(
    isLastYearReport?: boolean,
    lastYearDate?: string | Date,
    currentYearDate?: string | Date
  ): Date | null {
    const date = isLastYearReport ? lastYearDate : currentYearDate;
    return date ? new Date(date) : null;
  }

  if (!lastYearData && isLastYearReport && isSecundaria) {
    return <ErrorPage errorType={ERROR_TYPE.NOT_FOUND} />;
  }

  return (
    <>
      <GeneralProgressHeader
        loadingTable={loading}
        reportAvailable={!!mainReportData.rows.length}
        reportsStartDate={getReportDate(
          isLastYearReport,
          lastYearReportStartDate,
          initialDate.from
        )}
        reportsEndDate={getReportDate(
          isLastYearReport,
          lastYearReportEndDate,
          initialDate.to
        )}
        reportsDate={reportDate}
        handleDateChange={handleDateChange}
        handleDownload={handleDownload}
        googleDriveLoading={googleDriveLoading}
        isLastYearReport={isLastYearReport}
        standardStructure={standardStructure || ''}
        handleChangeStandard={setStandardStructure}
        isPrimary={isPrimary}
        hasLastYearReport={hasLastYearReport}
      />
      {renderTables({
        loading,
        checkReportHasData,
        mainReportData,
        adaptedReportsData,
        handleSendEvent,
        onExpandColumn,
        t,
        courses,
        reportType: 'general',
      })}
      <Modal
        isOpen={!!googleDriveUrl}
        onClose={onCloseModalGoogleDriveModal}
        title={t('modal.googleDrive.title')}
        closeOnEsc
        closeOnClickOutside
        modalWidth={700}
        buttons={[
          {
            children: t('modal.googleDrive.button.open'),
            variant: 'accent',
            onClick: openGoogleDriveFile,
          },
          {
            children: t('common.cancel'),
            variant: 'tertiary',
            onClick: onCloseModalGoogleDriveModal,
          },
        ]}
      >
        <>{t('modal.googleDrive.content.description')}</>
      </Modal>
    </>
  );
}
