import { Typography } from '../typography';
import { Icon } from '../icon';
import type { IconType } from '@innovamat/glimmer-icons';
import { useGlimmerTheme } from '../../theme';

import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const IconWrapper = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: grid;
    place-items: center;
  }
`;

const TypographyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: 0.25rem;
  }
`;

const Label = styled(Typography.Caption)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const Value = styled(Typography.Body3)`
  line-height: 1;
`;

type StatsCardProps = {
  icon: IconType;
  label: string;
  value: number;
};

const StatsCard = ({ icon, label, value }: StatsCardProps): JSX.Element => {
  const theme = useGlimmerTheme();
  const iconColor = theme.tokens.color.alias.cm.icon['icon-accent'].value;

  return (
    <Container role="listitem">
      <IconWrapper>
        <Icon
          icon={icon}
          size="L"
          iconColor={iconColor}
          aria-label={`${label} icon`}
        />
      </IconWrapper>
      <TypographyWrapper>
        <Label role="heading">{label}</Label>
        <Value>{value}</Value>
      </TypographyWrapper>
    </Container>
  );
};

export { StatsCard };
export type { StatsCardProps };
