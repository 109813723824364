import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { useDevice, type Column } from '@innovamat/glimmer-components';

import type {
  MockExamsStudentRow,
  StatementMetadata,
} from '../components/mock-exams-drawer-table';

export const StatementsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StatementsCol = styled.div<{
  width: number;
}>`
  ${({ width }) =>
    css`
      width: ${width * 3 + 30}px;
    `}

  overflow: hidden;
`;

const useMockExamsDrawerTableColumnsConf = (
  numStatements: number,
  statementsMetadata?: StatementMetadata[]
): Column<MockExamsStudentRow>[] => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  return [
    {
      id: 'firstName',
      label: t('mock-exams-drawer.table.first-name.label'),
      sortable: true,
      sortIgnoreCase: true,
      width: 112,
      minWidth: 112,
      sticky: isMobile ? false : true,
    },
    {
      id: 'lastName',
      label: t('mock-exams-drawer.table.last-name.label'),
      sortable: true,
      sortIgnoreCase: true,
      width: 112,
      minWidth: 112,
      sticky: isMobile ? false : true,
    },
    {
      id: 'status',
      label: t('common.status'),
      width: 145,
      minWidth: 145,
      sticky: isMobile ? false : true,
    },
    {
      id: 'score',
      width: 9,
      separator: true,
      sticky: isMobile ? false : true,
    },
    {
      id: 'score',
      label: t('mock-exams-drawer.table.score.label'),
      sortable: true,
      width: 76,
      minWidth: 76,
      sticky: isMobile ? false : true,
    },
    {
      id: 'statements',
      width: 9,
      separator: true,
      sticky: isMobile ? false : true,
    },
    {
      id: 'statements',
      subLabel: () => {
        return (
          <StatementsContainer>
            {Array.from({ length: numStatements }).map((_, index) => {
              const statementMetadata = statementsMetadata?.find(
                (statement) => statement.position === index
              );

              const width =
                statementMetadata?.answersMetrics?.largestAnswerWidth ?? 0;

              return (
                <StatementsCol width={width}>
                  {`${t('mock-exams-drawer.table.statements.sublabel')}${
                    index + 1
                  }`}
                </StatementsCol>
              );
            })}
          </StatementsContainer>
        );
      },
      label: t('mock-exams-drawer.table.statements.label'),
      isLargeLabel: false,
      minWidth: 100,
      width: '100%',
    },
  ];
};

export { useMockExamsDrawerTableColumnsConf };
