import { ImageComponent } from '../image-component';
import { POSITION, Position, ResourceCardIcon } from './resource-card-icon';
import { IconType } from '@innovamat/glimmer-icons';
import { ResourceCardVideo } from './resource-card-video';
import { ResourceCardStatusLabel } from './resource-card-status-label';
import { ResourceCardText } from './resource-card-text';
import { SessionDone } from '../session-done';
import {
  CardContainer,
  CheckboxContainer,
  DarkLayer,
  ImageContainer,
  NumberWrapper,
} from './resource-card.styled';
import { Typography } from '../typography';
import { useState } from 'react';

type IconStatus = 'jumpable' | 'downloadable' | 'not_available' | 'evaluable';
type IconValues = { icon: IconType; position: Position; tooltip: string };
type MessageStatus =
  | 'blocked'
  | 'not_done'
  | 'already_seen'
  | 'weekly_practice';
type MessageValues = { icon: IconType; message: string };
type Status = IconStatus | MessageStatus | 'new';

export type ResourceTypes =
  | 'vimeo'
  | 'genially'
  | 'pdf'
  | 'session'
  | 'applet'
  | 'webgl'
  | 'geogebra'
  | 'manipulativeEnvironment'
  | 'generic';

export type ResourceCardProps = {
  title: string;
  description: string;
  thumbnail: string;
  onClick: () => void;
  onMarkSession?: (value: boolean) => void;
  onToggleSolutions?: (value: boolean) => void;
  t: (key: string, fallback?: string) => string;
  status?: Status[];
  enumeration?: string;
  isDone?: boolean;
  areSolutionsEnabled?: boolean;
  type: ResourceTypes;
  stars?: number | null;
  videoInfo?: {
    duration: number;
    progress: number;
  };
  imgOptimizationUrl?: string;
};

const CARD_ICONS: Record<IconStatus, IconValues> = {
  jumpable: {
    icon: 'JumpableIcon',
    position: POSITION.right,
    tooltip: 'thumbnail.icon.tooltip.jumpable',
  },
  downloadable: {
    icon: 'DownloadFilesIcon',
    position: POSITION.top,
    tooltip: 'thumbnail.icon.tooltip.download',
  },
  not_available: {
    icon: 'NotAvailableIcon',
    position: POSITION.left,
    tooltip: 'thumbnail.icon.tooltip.notavailable',
  },
  evaluable: {
    icon: 'EvaluationIcon',
    position: POSITION.top,
    tooltip: 'thumbnail.icon.tooltip.evaluable',
  },
};

const CARD_MESSAGES: Record<MessageStatus, MessageValues> = {
  blocked: {
    icon: 'LockedIcon',
    message: 'session.blockedInClassroom',
  },
  not_done: {
    icon: 'NotDoneClassIcon',
    message: 'session.undoneInClassroom',
  },
  already_seen: {
    icon: 'SeenInClassIcon',
    message: 'session.seenInClass',
  },
  weekly_practice: {
    icon: 'LockedIcon',
    message: 'weeklyPractice.title',
  },
};

const DARK_LAYER_VALUES = [
  'not_available',
  'blocked',
  'not_done',
  'weekly_practice',
];

export function ResourceCard({
  title,
  description,
  thumbnail,
  onClick,
  status,
  enumeration,
  onToggleSolutions,
  onMarkSession,
  isDone,
  areSolutionsEnabled,
  type,
  videoInfo,
  stars,
  t,
  imgOptimizationUrl,
}: ResourceCardProps): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  const hasDarkLayer =
    status?.some((value) => DARK_LAYER_VALUES.includes(value)) ||
    (isDone && onMarkSession) ||
    videoInfo?.progress === 100;

  const hasStatusMessage = status?.some(
    (value) =>
      value === 'blocked' || value === 'not_done' || value === 'already_seen'
  );

  const isNotClickable =
    status?.includes('not_available') || status?.includes('blocked');

  const resourceCardStatus = status
    ?.map((value) => CARD_ICONS[value as IconStatus] ?? null)
    .filter(Boolean);

  const resourceCardMessages = status
    ?.map((value) => CARD_MESSAGES[value as MessageStatus] ?? null)
    .filter(Boolean);

  return (
    <CardContainer
      onClick={isNotClickable ? undefined : onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role="article"
    >
      <ImageContainer className="img-container">
        {hasDarkLayer && <DarkLayer data-testid="dark-layer" />}
        {enumeration && (
          <NumberWrapper>
            <Typography.Subtitle1>{enumeration}</Typography.Subtitle1>
          </NumberWrapper>
        )}

        {resourceCardStatus?.map(({ icon, position, tooltip }, index) => (
          <ResourceCardIcon
            key={index}
            icon={icon}
            position={position}
            tooltip={t(tooltip)}
          />
        ))}
        <ImageComponent
          alt="Resource thumbnail"
          className="image"
          src={thumbnail}
          width="100%"
          height="100%"
          baseUrl={imgOptimizationUrl || ''}
          isLazyLoading
          hasOptimization={Boolean(imgOptimizationUrl)}
        />

        {onMarkSession && !hasStatusMessage && (
          <CheckboxContainer onClick={(event) => event.stopPropagation()}>
            <SessionDone
              textSessionComplete={t('session.completed')}
              textActivateSolution={t('contents.activateSolutions.message')}
              checked={isDone}
              onCheck={onMarkSession}
              showSolutions={Boolean(onToggleSolutions)}
              isActive={areSolutionsEnabled || Boolean(!onToggleSolutions)}
              onActive={onToggleSolutions}
              isInThumbnail
              hoverOnThumbnail={isHovered}
            />
          </CheckboxContainer>
        )}

        {resourceCardMessages?.map(({ icon, message }) => (
          <ResourceCardStatusLabel
            icon={icon}
            text={t(message)}
            hasProgressBar={videoInfo?.progress! > 0}
          />
        ))}

        {type === 'vimeo' && videoInfo && <ResourceCardVideo {...videoInfo} />}
      </ImageContainer>

      <ResourceCardText
        isNew={Boolean(status?.includes('new'))}
        description={description}
        title={title}
        stars={stars}
        t={t}
      />
    </CardContainer>
  );
}
